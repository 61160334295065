<template>
    <div>Esporta Tipologie Società</div>
</template>

<script>
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

 export default {
     mounted() {
         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Impostazioni", route: "/settings" },
             { title: "Tipologie Società", route: "/settings/company-type/index" },
             { title: "Esporta Tipologie Società" }
         ]);
     }
 };
</script>
